import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import LamyaliFarms from '../components/Locations/LamyaliFarms'
const Lamyali = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Lamyali Farms"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Lamyali Farms"
            />
            <LamyaliFarms />
            <Footer />
        </Layout>
    );
}

export default Lamyali