import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import SidebarPromotionOne from '../Marketing/SidebarPromotionOne'

const LamyaliFarms = () => {
    return (
        <section className="about-area ptb-10">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    Lamyali Farms
                                </span>
                                <h2>Scenic and Calm Riverfront in Green Vally</h2>
                                <p> Lamyali Farms retreat at Hainvel riverfront and valley is an incredibly rejuvenating and relaxing experience.
                                    The gentle sounds of the flowing water, the rustling of the leaves in the breeze, and the natural beauty of
                                    the surrounding scenery all work together to create a sense of tranquility and peace.One of the best things
                                    about Lamyali Farms is the opportunity to disconnect from the stresses and distractions of everyday life.
                                    Away from the hustle and bustle of the city, you can truly focus on your own well-being and recharge your batteries</p>

                                <p> You also have the chance to explore the natural wonders of the surrounding area. Perhaps you can take a hike through
                                    the nearby hills or forests, or even go fishing in the river itself. Perisun also offer organized activities, such as yoga
                                    and meditation classes, Spa Session and dip in cool medictaed river water that can help you deepen your connection to
                                    nature and find inner peace.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <p> At night, the riverfront can offer its own unique magic. With the stars twinkling overhead and the soft
                                        glow of the moon reflecting off the water, it's a wonderful opportunity to reflect on the day and simply
                                        enjoy the beauty of the world around you.
                                        If you're looking for a way to relax and rejuvenate, Lamyali Farms at riverfront valley is definitely worth considering.
                                        With its natural beauty, peaceful surroundings, and opportunities for adventure and self-reflection, it's a great way to
                                        get away from it all and find inner peace.  </p>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default LamyaliFarms;